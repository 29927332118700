import { Link } from "gatsby"
import tw, { styled } from "twin.macro"

const styles = {
  default: {
    headings: tw``,
    serif_headings: tw`font-serif`,
    text: tw`font-normal`,
  },
  align: {
    left: tw`text-left`,
    center: tw`text-center`,
    right: tw`text-right`,
    "md:right": tw`text-left md:text-right`,
  },
  colour: {
    white: tw`text-white`,
    black: tw`text-black`,
    red: tw`text-red`,
    green: tw`text-green`,
    yellow: tw`text-yellow`,
  },
  spacing: {
    small: tw`mb-0-6`,
    middle: tw`mb-1-2`,
    default: tw`mb-1-6`,
    sizeGuide:tw`p-1`,
    location:tw`py-1`,
    SEO:tw`pt-6 pb-4`,
  },
}

export const Heading1 = styled.h1<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-50 leading-none lg:text-100 uppercase font-bold`}
`
export const Heading2 = styled.h2<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-72 uppercase leading-35 font-bold`}
`
export const Heading3 = styled.h3<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-60 leading-35 uppercase font-bold`}
`

export const Heading4 = styled.h4<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-50 leading-45 uppercase font-bold`}
`

export const Heading5 = styled.h5<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-50 leading-45 uppercase`}
`
export const Heading6 = styled.h6<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-40 leading-35 uppercase`}
`
export const Heading7 = styled.h6<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-40 leading-50`}
`
export const Heading8 = styled.h6<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-36 leading-45 uppercase`}
`
export const Heading9 = styled.h6<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-24 leading-30 uppercase`}
`
export const Heading10 = styled.h6<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-32 leading-40`}
`
export const Heading11 = styled.h6<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-22 leading-26`}
`
export const Heading12 = styled.h6<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-20 leading-26 uppercase`}
`

export const SubHeading = styled.h6<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-12 leading-14 uppercase`}
`
export const SubHeadingSerif = styled.h6<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-14 xl:text-20 leading-14 font-serif italic`}
`
export const SubHeadingSerifAlt = styled.h6<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-12 xl:text-18 leading-14 font-serif italic`}
`

export const LargeBody = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-18 leading-21`}
`

export const Body = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${({ size }) => size == "small" ? tw`text-12 leading-21` : tw`text-12`}
`

// bodyXXL
export const bodyXXL = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`xl:text-24 leading-30`}
`
// bodyXL
export const bodyXL = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`xl:text-20 leading-30`}
`
// bodyLG
export const bodyLG = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`xl:text-18 leading-26`}
`
// bodySM
export const bodySM = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`xl:text-14 leading-21`}
`
// bodyTinyAlt
export const bodyTinyAlt = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`xl:text-12 leading-19`}
`
// bodyTiny
export const bodyTiny  = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`xl:text-12 leading-19 font-light`}
`
// bodyExtraTiny
export const bodyExtraTiny  = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`xl:text-10 leading-14 font-light`}
`
// bodyExtraExtraTiny
export const bodyExtraExtraTiny  = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`xl:text-9 leading-14 font-light`}
`
// bodyExtraExtraExtraTiny
export const bodyExtraExtraExtraTiny  = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`xl:text-8 leading-14 font-light`}
`
// caption16
export const caption16  = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`xl:text-16 leading-21`}
`
// caption12
export const caption12  = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`xl:text-12 leading-26`}
`
// caption10
export const caption10  = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`xl:text-10 leading-14`}
`
// caption8
export const caption8  = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-8 leading-10 uppercase`}
`

// Serif Headings
export const Heading1Serif = styled.h1<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-50 lg:text-72 leading-none lg:leading-72`}
`
export const Heading2Serif = styled.h2<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-56 leading-58`}
`
export const Heading3Serif = styled.h3<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-50 leading-58`}
`

export const Heading4Serif = styled.h4<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-46 leading-45`}
`

export const Heading5Serif = styled.h5<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-36 leading-35`}
`
export const Heading6Serif = styled.h6<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-30 leading-30`}
`
export const Heading7Serif = styled.h6<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-22 leading-26`}
`
export const Heading8Serif = styled.h6<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-20 leading-26`}
`
export const Heading9Serif = styled.h6<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-30 xl:text-18 leading-21`}
`
export const bodyXXLSerif = styled.h6<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-16 xl:text-32 leading-35`}
`
export const bodyXLSerif = styled.h6<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-14 xl:text-28 leading-30`}
`
export const bodyLGSerif = styled.h6<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-14 xl:text-25 leading-26`}
`
export const bodySerif = styled.h6<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-14 xl:text-20 leading-26`}
`
export const bodySMSerif = styled.h6<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-14 xl:text-18 leading-21`}
`
export const bodyTinySerif = styled.h6<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-14 xl:text-16 leading-21`}
`
export const bodyExtraTinySerif = styled.h6<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-12 leading-14`}
`
export const bodyExtraExtraTinySerif = styled.h6<TextProps>`
  ${styles.default.serif_headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-9.5 leading-14`}
`
export const RichTextContent = styled.div<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-12`}
  > *, > div > * {
    ${tw`mb-1-6 last:mb-0`}
  }
`

type TextProps = {
  align?: "left" | "center" | "right" | "md:right"
  colour?: "white" | "black" | "red" | "green"
  spacing?: "default" | "12"
}
